import { GridRenderCellParams } from '@mui/x-data-grid';
import { t } from 'i18next';
import { useContext, useState } from 'react';

import DatagridWrapper, { DataGridId } from '../../components/DatagridWrapper';
import { Role, UserResponse, roleToString } from '../../models/User';
import { NotCorrectRights } from '../Permission/NotCorrectRights';
import { AbilityContext, Can } from './logic/Can';
import { UserDetailsButton } from './userDetailsButton/UserDetailsButton';

import { History } from '@mui/icons-material';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { OrganisationContext } from '../../components/Layout/OrganisationPicker';
import { OpenInNewTabButton } from '../../components/OpenInNewTabButton';
import { PaginationQueryPackage } from '../../models/API/QueryParams/PaginationQueryPackage';
import { GetManyPackage } from '../../redux/GetManyPackage';
import { PermissionEnum } from '../Permission/PermissionEnum';
import { DateToFormattedString } from '../../components/Logic/DateLogic';
import { GridColDef } from '@mui/x-data-grid-pro';
import { SimpleBaseOrganisation } from '../../models/Organisation';

const getCurrentUser = (users: UserResponse[], params: GridRenderCellParams<any, any, any>): UserResponse => {
    const user = users.find(x => x.uuid === params.id)!;
    return user;
}

class UserListProps {
    getUsers!: UseQuery<QueryDefinition<GetManyPackage, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, UserResponse[], any>>
    idToFetch?: string
    simpleView?: boolean = false;
    dataGridId?: DataGridId
}

export const UserList = (props: UserListProps) => {
    const ability = useContext(AbilityContext);
    const organisationContext = useContext(OrganisationContext)?.organisation
    const canReadList = ability.can(PermissionEnum.USER_READ, organisationContext)
    if (!canReadList)
        return <NotCorrectRights />

    return (<UserListContent {...props} />)
}

export const UserListContent = (props: UserListProps) => {


    const currentResult = props.getUsers({
        pagination: new PaginationQueryPackage(),
        uuid: props.idToFetch
    })

    const [clickedRow, setClickedRow] = useState<UserResponse | undefined>(undefined)
    const [uuid, setUuid] = useState(crypto.randomUUID()) //To rerender buttons when clicking a row

    if (currentResult.data === undefined)
        currentResult.data = []


    const getColumns = (users: UserResponse[]) => {

        const simpleView: GridColDef[] = [
            { field: "organisation", sortable: true, type: "string", headerName: t('Organisation'), valueGetter: (org: SimpleBaseOrganisation) => org.name, flex: 1 },
            { field: "name", sortable: true, type: "string", headerName: t('Name'), valueGetter: (_, row: UserResponse) => row.first_name + " " + row.last_name, flex: 1 },
            { field: "email", sortable: true, type: "string", headerName: t('Email'), flex: 1 },
        ];

        const advancedView: GridColDef[] = [
            { field: "role", sortable: true, type: "singleSelect", valueOptions: Object.values(Role).map(e => t(e) + ""), headerName: t('Role'), valueGetter: (role: Role) => t(roleToString(role)), flex: 1 },
            { field: "keycloak_enabled", sortable: true, type: "boolean", headerName: t('Enabled'), flex: 0.5 },
            { field: "latest_activity", sortable: true, type: "dateTime", headerName: t('Latest activity'), valueGetter: (latestActivity) => latestActivity && new Date(latestActivity), renderCell: (params: { row: UserResponse }) => DateToFormattedString(params.row.latest_activity), flex: 1 },
        ];

        const buttonsView: GridColDef[] = [
            {
                field: 'events',
                sortable: false,
                hideable: false,
                type: "string",
                headerName: t('Actions'),
                minWidth: props.simpleView ? 100 : 220,
                renderCell: (params => {
                    const user = getCurrentUser(users, params)
                    return (props.simpleView ?
                        <OpenInNewTabButton url={`/users/${user.uuid}`} />
                        :
                        <>
                            <Can I={PermissionEnum.USER_READ} this={user}>
                                <UserDetailsButton
                                    key={JSON.stringify(uuid)}
                                    userUuid={user.uuid}
                                    openFromStart={user.uuid == clickedRow?.uuid}
                                    onClose={() => setClickedRow(undefined)}
                                />
                            </Can>
                            <Can I={PermissionEnum.AUDIT_READ} this={user}>
                                <OpenInNewTabButton tooltipText='Events' icon={<History />} url={`/events?audit_user_uuid=${user.uuid}`} />
                            </Can>
                            <Can I={PermissionEnum.USER_READ} this={user}>
                                <OpenInNewTabButton url={`/users/${user.uuid}`} />
                            </Can>
                        </>
                    )
                })
            }
        ];

        const simpleList = simpleView;
        const advancedList = advancedView;
        const listToShow = props.simpleView ? simpleList : simpleList.concat(advancedList);
        return listToShow.concat(buttonsView);
    }

    return (
        <DatagridWrapper
            dataGridId={props.dataGridId ?? DataGridId.USERS}
            onRowClick={(row) => { setClickedRow(row.row); setUuid(crypto.randomUUID()) }}
            loading={currentResult.isLoading}
            rows={currentResult.data}
            columns={getColumns(currentResult.data)}
        />
    )
}

