export enum ErrorCodeEnum {
    GENERIC = 1,
    BAD_REQUEST = 2,
    RESOURCE_NOT_FOUND = 3,
    UUID_DOES_NOT_MATCH = 4,
    INVALID_PAGINATION = 5,
    INVALID_FIELD_VALUE = 6,
    MISSING_PARAMETER = 7,
    MISSING_IP_ADDRESSES_ON_CLIENT = 8,
    INVALID_AGREEMENT_STATUS = 9,
    UNPROCESSABLE_ENTITY = 10,
    CONFLICT = 12,
    SUBNET_OVERLAP = 13,
    RESOURCE_NOT_ACCESSIBLE = 14,
    AUDIT_API_ERROR = 15,
    UNKNOWN_USER = 16,
    INVALID_TOKEN = 17,
    SERVICE_WINDOW_UPDATE_NOT_ALLOWED = 18,
    REDUNDANT_REQUEST = 19,
    ORGANISATION_NOT_FOUND = 20,
    SERVICE_NOT_FOUND = 21,
    SERVICE_WINDOW_NOT_FOUND = 22,
    BAD_GATEWAY = 23,
    SUBNET_NOT_FOUND = 24,
    HOSTING_SITE_NOT_FOUND = 25,
    SUBNET_IN_USE = 26,
    INVALID_SERVICE_APPROVER = 27,
    CLIENT_NOT_FOUND = 28,
    EXTERNAL_CONTACT_NOT_FOUND = 29,
    PORTALIAS_NOT_FOUND = 30,
    USER_NOT_FOUND = 31,
    USER_NOTIFICATION_NOT_FOUND = 32,
    IP_ADDRESS_NOT_FOUND = 33,
    SMOKEPING_NOT_FOUND = 34,
    ROUTER_NOT_FOUND = 35,
    AGREEMENT_NOT_FOUND = 36,
    DNS_SUFFIX_NOT_FOUND = 37,
    CONTACT_LABEL_NOT_FOUND = 38,
    SERVICE_MONITORING_NOT_FOUND = 39,
    SERVICETAG_NOT_FOUND = 40,
    INVALID_SERVICE_TAG = 41,
    OPERATION_REQUIRES_FORCE = 42,
    REASON_FOR_NO_ENCRYPTION_REQUIRED = 43,
    SERVICE_WINDOW_MUST_HAVE_SERVICE = 44,
    SERVICE_NOT_AT_SERVICE_WINDOW = 45,
    CONTACT_NOT_FOUND = 46,
    DUPLICATE_CONTACT = 47,
    ENDPOINT_NOT_FOUND = 48,
    CONFLICTING_EXTERNAL_CONTACT = 49,
    CONFLICTING_USER = 50,
    INVALID_CONTACT_LABEL = 51,
    CONTACT_NOT_CLIENT_CONTACT = 52,
    CONTACT_NOT_SERVICE_CONTACT = 53,
    PASSWORD_AND_USERNAME_SHOULD_NOT_BE_PROVIDED = 54,
    BOTH_PASSWORD_AND_USERNAME_SHOULD_BE_PROVIDED = 55,
    KEYCLOAK_ERROR = 56,
    USER_IS_NOT_SERVICE_APPROVER_ON_SERVICE = 57,
    ENDPOINT_ON_SERVICE_WITH_APPROVED_AGREEMENTS = 58,
    DNS_SUFFIX_IN_USE_AT_HOSTING_SITE = 59,
    EMAIL_NOT_SENT = 60,
    USER_ALREADY_HAS_AUTHORISATION_TO_ORGANISATION = 61,
    USER_CANNOT_HAVE_AUTHORISATION_TO_OWN_ORGANISATION = 62,
    PARENTUSER_IS_AN_AUTHORISATIONUSER = 63,
    USER_NOT_ALLOWED_TO_USE_PROVIDED_AUTHORISATION = 64,
    SETTING_HIGHER_PRIVILEGES_THAN_OWN_USER = 65,
    CONTACT_NOT_ORGANISATION_CONTACT = 66,
    USER_DOES_NOT_BELONG_TO_ORGANISATION = 67,
    INVALID_DNS_NAME = 69,
    HOSTING_SITE_NAME_ALREADY_IN_USE = 70,
    ORGANISATION_NAME_ALREADY_IN_USE = 71,
    PORTALIAS_IS_IN_USE = 73,
    SERVICE_TAG_ALREADY_ON_HOSTING_SITE = 74,
    HOSTING_SITE_SDN_V4_ID_ALREADY_IN_USE = 75,
    ACCESS_CONTROL_LIST_NOT_FOUND = 76,
    COPY_FROM_USER_FROM_DIFFERENT_ORGANISATION = 77,
    USER_ALREADY_SERVICE_APPROVER_ON_SERVICE = 78,
    CPE_NOT_FOUND = 79,
    SUBNET_MOVE_INVALID_SERVICES = 80,
    SUBNET_MOVE_INVALID_CLIENTS = 81,
    EXPECTED_MATCHING_DOMAIN_NAME = 82,
    USER_CANNOT_DELETE_ITS_OWN_ORGANISATION = 83,
    CANNOT_DELETE_ORGANISATION_WITH_APPROVED_AGREEMENTS = 84,
    CANNOT_DELETE_ORGANISATION_WHILE_ADMINISTRATING_HOSTING_SITES = 85,
    CANNOT_DELETE_OWN_USER = 86,
}
