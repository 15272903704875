import { Box } from '@mui/material';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useTranslation } from 'react-i18next';
import { ServiceInfoButton } from '../feature/ServiceApi/serviceInfoDialog/ServiceInfoButton';
import { OrganisationResponse } from '../models/Organisation';
import { ServiceResponse } from '../models/Service';
import { GetManyPackage } from '../redux/GetManyPackage';
import { OrganisationBadge } from './Badges/OrganisationBadge';
import { ServiceBadge } from './Badges/ServiceBadge';
import EasyFormColumn from './Forms/EasyFormColumn';
import EasyFormMany from './Forms/EasyFormMany';
import { FieldEditorRender } from './Forms/Renders/FieldEditorRender';
import { TextRenderer } from './Forms/Renders/TextRenderer';

interface ServiceAssociationsComponentPackage {
    uuid: string
    getContactsByIdAction?: UseQuery<QueryDefinition<GetManyPackage, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, ServiceResponse[], any>>
    contactsTitle?: string
    getApproversByIdAction?: UseQuery<QueryDefinition<GetManyPackage, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, ServiceResponse[], any>>
    approversTitle?: string
}

export default function ServiceAssociationsComponent(serviceAssociationsComponentPackage: ServiceAssociationsComponentPackage) {
    const { t } = useTranslation()

    const getServiceRows = (services: ServiceResponse[]) => {
        const rows: Array<FieldEditorRender[]> = []
        services?.forEach((service) => {
            const newRow: FieldEditorRender[] = [
                new TextRenderer({
                    displayText: t("Name"),
                    fieldName: "",
                    nestedfieldName: "",
                    uniqueId: service.uuid,
                    render: () => <ServiceBadge ressource={service} />
                }),
                new TextRenderer({
                    displayText: "Organisation",
                    fieldName: "",
                    nestedfieldName: "owner_organisation",
                    uniqueId: service.uuid,
                    render: (organisation) => <OrganisationBadge ressource={organisation as OrganisationResponse} />
                })
            ]

            rows.push(newRow)
        })
        return rows;
    }

    return (
        <Box sx={{ display: 'flex' }}>
            {serviceAssociationsComponentPackage.contactsTitle && serviceAssociationsComponentPackage.getContactsByIdAction ?
                <>
                    <Box sx={{ width: "48%" }}>
                        <EasyFormMany
                            confirmDeletionText=""
                            isAllowedToDelete={() => false}
                            isAllowedToEdit={() => false}
                            isAllowedToCreate={() => false}
                            ressourceOwnerId={serviceAssociationsComponentPackage.uuid}
                            arrayPath=""
                            idToFetch={serviceAssociationsComponentPackage.uuid}
                            getManyByIdAction={serviceAssociationsComponentPackage.getContactsByIdAction}
                            jsxAtButtons={(service) => (
                                <ServiceInfoButton service={service} />
                            )}
                            SignupSchema={undefined}
                            columns={[
                                new EasyFormColumn(serviceAssociationsComponentPackage.contactsTitle, "", []),
                            ]}
                            getFieldRows={getServiceRows}
                        />
                    </Box>
                    <Box sx={{ width: "4%" }}></Box>
                </>
                : <></>}
            {serviceAssociationsComponentPackage.approversTitle && serviceAssociationsComponentPackage.getApproversByIdAction ?
                <Box sx={{ width: "48%" }}>
                    <EasyFormMany
                        confirmDeletionText=""
                        isAllowedToDelete={() => false}
                        isAllowedToEdit={() => false}
                        isAllowedToCreate={() => false}
                        ressourceOwnerId={serviceAssociationsComponentPackage.uuid}
                        arrayPath=""
                        idToFetch={serviceAssociationsComponentPackage.uuid}
                        getManyByIdAction={serviceAssociationsComponentPackage.getApproversByIdAction}
                        jsxAtButtons={(service) => (
                            <ServiceInfoButton service={service} />
                        )}
                        SignupSchema={undefined}
                        columns={[
                            new EasyFormColumn(serviceAssociationsComponentPackage.approversTitle!, "", []),
                        ]}
                        getFieldRows={getServiceRows}
                    />
                </Box>
                : <></>}
        </Box>

    )
}