import { Info } from "@mui/icons-material"
import { Tab } from "@mui/material"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import DrawerButton from "../../../../components/DrawerButton"
import { EntityMetaDataComponent } from "../../../../components/EntityMetaDataComponent"
import { CustomTabs } from "../../../../components/Forms/CustomTabs"
import UseUrlParam from "../../../../components/Logic/UseUrlProps"
import RessourceMenu from "../../../../components/RessourceMenu"
import TabPanel from "../../../../components/TabPanel"
import TooltipComponent from "../../../../components/TooltipComponent"
import { PermissionEnum } from "../../../Permission/PermissionEnum"
import { AbilityContext } from "../../../UserApi/logic/Can"
import { useDeleteOrganisationMutation, useGetOrganisationByIdQuery } from "../../redux/organisationApiSlice"
import { OrganisationButtonProps } from "../OrganisationButtonProps"
import { OrganisationDeleteContent } from "./OrganisationDeleteContent"
import InfoTab from "./tabs/InfoTab"
import RoutersTab from "./tabs/RoutersTab"

export interface OrganisationTabProps {
    organisationId: string
}

export const OrganisationInfoButton = (props: OrganisationButtonProps) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState<boolean>(props.openFromStart ?? false)
    return (
        <DrawerButton
            open={open}
            setOpen={setOpen}
            onClose={props.onClose}
            buttonContent={
                <TooltipComponent title={t("Details") + ""}>
                    <Info />
                </TooltipComponent>
            } footerContent={
                <EntityMetaDataComponent entity_uuid={props.organisationUuid} />
            }>

            <OrganisationInfoContent {...props}></OrganisationInfoContent>
        </DrawerButton>
    )
}



export const OrganisationInfoContent = (props: OrganisationButtonProps | UseUrlParam) => {

    const params = useParams();
    const urlPropsUuid = (props as UseUrlParam).shouldGetOrganisationFromUrl ? params["organisationUuid"] : "";
    const orgPropsUuid = (props as OrganisationButtonProps).organisationUuid

    const organisationUuid = orgPropsUuid ?? urlPropsUuid;

    const [value, setValue] = React.useState(0);
    const [readyForDelete, setReadyForDelete] = React.useState(false);
    const { t } = useTranslation()

    const ability = useContext(AbilityContext);
    const organisationResult = useGetOrganisationByIdQuery(organisationUuid!)

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    if (organisationResult.data == undefined)
        return <></>

    const labels = []
    const tabPanel = []

    labels.push(t("Info") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><InfoTab organisationId={organisationResult.data.uuid!} {...props} /></TabPanel>)

    if (ability.can(PermissionEnum.ORGANISATION_READ, organisationResult.data)) {
        labels.push(t("Routers") + "")
        tabPanel.push(<TabPanel value={value} index={tabPanel.length}><RoutersTab organisationId={organisationResult.data.uuid!} {...props} /></TabPanel>)
    }

    return (
        <>
            <CustomTabs value={value} onChange={handleChange}
                rightMenu={
                    <RessourceMenu
                        isAllowedToDelete={(data) => ability.can(PermissionEnum.GOD, data)}
                        nameOfRessource="organisation"
                        primaryButtonText={t("Organisation") + ": " + organisationResult.data.name}
                        idToFetch={organisationResult.data.uuid}
                        deleteMutation={useDeleteOrganisationMutation}
                        getByIdAction={useGetOrganisationByIdQuery}
                        deleteDialogMaxWidth="lg"
                        deleteButtonContent={readyForDelete ? undefined
                            : <OrganisationDeleteContent organisation={organisationResult.data} setReadyForDelete={setReadyForDelete} />}
                    />
                }>
                {labels.map(label => (<Tab key={label} label={label} />))}
            </CustomTabs>
            {tabPanel.map(tabPanel => tabPanel)}
        </>
    )
}