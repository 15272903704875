import { t } from 'i18next';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import Client from '../../models/Client';
import { ClientInfoButton } from './clientInfoDialog/ClientInfoButton';

import { Handshake, History } from '@mui/icons-material';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import DatagridWrapper, { DataGridId } from '../../components/DatagridWrapper';
import { OrganisationContext } from '../../components/Layout/OrganisationPicker';
import GetLastElementsInList from '../../components/Logic/ListLogic';
import { OpenInNewTabButton } from '../../components/OpenInNewTabButton';
import { PaginationQueryPackage } from '../../models/API/QueryParams/PaginationQueryPackage';
import { GetManyPackage } from '../../redux/GetManyPackage';
import { NotCorrectRights } from '../Permission/NotCorrectRights';
import { PermissionEnum } from '../Permission/PermissionEnum';
import { AbilityContext, Can } from '../UserApi/logic/Can';
import { GridColDef } from '@mui/x-data-grid-pro';
import { CountBadge } from '../../components/CountBadge';

class ClientListProps {
    getClients?: UseQuery<QueryDefinition<GetManyPackage, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, Client[], any>>
    clients?: Client[]
    setCheckedClients?: Dispatch<SetStateAction<Client[]>>
    maxSelected?: number
    checkedClients?: Client[]
    idToFetch?: string
    simpleView?: boolean = false;
    dataGridId?: DataGridId
}

export const ClientList = (props: ClientListProps) => {
    const ability = useContext(AbilityContext);
    const organisationContext = useContext(OrganisationContext)?.organisation;

    const canReadList = ability.can(PermissionEnum.CLIENT_READ, organisationContext)
    if (!canReadList)
        return <NotCorrectRights />

    return (<ClientListContent {...props} />)
}

export function ClientListContent(props: ClientListProps) {

    const [clickedRow, setClickedRow] = useState<Client | undefined>(undefined)
    const [uuid, setUuid] = useState(crypto.randomUUID()) //To rerender buttons when clicking a row

    const getColumns = (clients: Client[]) => {

        const simpleView: GridColDef[] = [
            //Data
            { field: 'short_id', sortable: true, type: "number", headerName: t('ID'), renderCell: (params) => <div>{params.row.short_id}</div>, flex: 0.5 },
            { field: 'name', sortable: true, type: "string", headerName: t('Client'), flex: 3 },
            { field: 'description', sortable: true, type: "string", headerName: t('Description'), flex: 3 },
        ];

        const advancedView: GridColDef[] = [
            { field: 'active_agreements', renderCell: (params) => <CountBadge prefix={t("agreements")} icon={Handshake} count={params.row.active_agreements} />, sortable: true, type: "number", headerName: t('Agreements'), flex: 1 },
            { field: "ip_addresses", sortable: false, headerName: t('IP addresses'), type: "string", valueGetter: (ips) => [...new Set(ips)], flex: 2 },
        ];


        const buttonsView: GridColDef[] = [
            {
                field: 'events',
                sortable: false,
                hideable: false,
                type: "string",
                valueGetter: () => "",
                headerName: t('Actions'),
                minWidth: props.simpleView ? 100 : 220,
                renderCell: (params => {
                    const client = clients.find(x => x.uuid === params.id)!;
                    return (props.simpleView ?
                        <OpenInNewTabButton url={`/clients/${client.uuid}`} />
                        :
                        <>
                            <Can I={PermissionEnum.CLIENT_READ} this={client}>
                                <ClientInfoButton
                                    key={JSON.stringify(uuid)}
                                    openFromStart={client.uuid == clickedRow?.uuid}
                                    onClose={() => setClickedRow(undefined)}
                                    client={client} />
                            </Can>
                            <Can I={PermissionEnum.AUDIT_READ} this={client}>
                                <OpenInNewTabButton tooltipText='Events' icon={<History />} url={`/events?entity_uuid=${client.uuid}`} />
                            </Can>
                            <Can I={PermissionEnum.CLIENT_READ} this={client}>
                                <OpenInNewTabButton url={`/clients/${client.uuid}`} />
                            </Can>
                        </>
                    )
                })
            }
        ];

        const simpleList = simpleView;
        const advancedList = advancedView;
        const listToShow = props.simpleView ? simpleList : simpleList.concat(advancedList);
        return listToShow.concat(buttonsView);
    }

    const clients = getClients(props);

    return (
        <DatagridWrapper
            dataGridId={props.dataGridId ?? DataGridId.CLIENTS}
            onRowClick={(row) => { setClickedRow(row.row); setUuid(crypto.randomUUID()) }}
            loading={clients == undefined}
            rows={clients ?? []}
            columns={getColumns(clients ?? [])}
            onRowSelectionModelChange={(rowSelectionModel) => {
                if (props.setCheckedClients) {
                    const newCheckedClients = GetLastElementsInList(rowSelectionModel, props.maxSelected).map(uuid => clients?.find(client => client.uuid == uuid));
                    props.setCheckedClients(newCheckedClients.filter((item): item is Client => !!item))
                }
            }}
            rowSelectionModel={props.checkedClients?.map(x => x.uuid)}
            checkboxSelection={props.checkedClients != undefined}
            initialColumnVisibilityModel={{
                ip_addresses: false
            }}
        />
    )

    function getClients(props: ClientListProps) {
        if (props.clients != undefined)
            return props.clients


        const currentResult = props.getClients?.({
            pagination: new PaginationQueryPackage(),
            uuid: props.idToFetch
        });

        return currentResult?.data
    }
}

