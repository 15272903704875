import InfoIcon from '@mui/icons-material/Info';
import { Tab } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DrawerButton from "../../../components/DrawerButton";
import { EntityMetaDataComponent } from "../../../components/EntityMetaDataComponent";
import { CustomTabs } from '../../../components/Forms/CustomTabs';
import UseUrlParam from "../../../components/Logic/UseUrlProps";
import RessourceMenu from "../../../components/RessourceMenu";
import TabPanel from "../../../components/TabPanel";
import TooltipComponent from '../../../components/TooltipComponent';
import { PermissionEnum } from "../../Permission/PermissionEnum";
import { AbilityContext } from "../../UserApi/logic/Can";
import { useDeleteAuthorizationMutation, useGetAuthorizationByIdQuery } from "../redux/authroisationApiSlice";
import InfoTab from "./tabs/InfoTab";
import ServiceAssociationsComponent from '../../../components/ServiceAssociationsComponent';
import { useGetServicesByApproverQuery } from '../../ServiceApi/redux/serviceApiSlice';

export interface AuthorisationDetailsButtonProps {
    authorisation_uuid: string
    openFromStart?: boolean
    onClose?: () => void
}

export const AuthorisationDetailsButton = (props: AuthorisationDetailsButtonProps) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState<boolean>(props.openFromStart ?? false)
    return (
        <DrawerButton
            open={open}
            setOpen={setOpen}
            onClose={props.onClose}
            buttonContent={
                <TooltipComponent title={t("Details") + ""}>
                    <InfoIcon />
                </TooltipComponent>
            }
            footerContent={
                <EntityMetaDataComponent entity_uuid={props.authorisation_uuid} />
            }>

            <AuthorisationDetailsContent authorisation_uuid={props.authorisation_uuid} afterDeleteAction={() => setOpen(false)}></AuthorisationDetailsContent>
        </DrawerButton>
    )
}

export interface AuthorisationDetailsContentProps {
    authorisation_uuid: string
    afterDeleteAction: () => void
}

export const AuthorisationDetailsContent = (props: AuthorisationDetailsContentProps | UseUrlParam) => {

    const params = useParams();
    const urlPropsUuid = (props as UseUrlParam).shouldGetOrganisationFromUrl ? params["authorisationUuid"] : "";
    const authorisationPropsUuid = (props as AuthorisationDetailsButtonProps).authorisation_uuid
    const authorisationUuid = authorisationPropsUuid ?? urlPropsUuid;

    const [value, setValue] = React.useState(0);
    const { t } = useTranslation()
    const { data } = useGetAuthorizationByIdQuery(authorisationUuid);
    const ability = useContext(AbilityContext)

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    if (!data)
        return <></>

    const labels = []
    const tabPanel = []

    labels.push(t("Info") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><InfoTab authorisation_uuid={authorisationUuid!} /></TabPanel>)

    if (ability.can(PermissionEnum.SERVICE_READ, data)) {
        labels.push(t("Service associations") + "")
        tabPanel.push(<TabPanel value={value} index={tabPanel.length}><ServiceAssociationsComponent
            getApproversByIdAction={useGetServicesByApproverQuery} approversTitle="Services with authorisation as approver"
            uuid={authorisationUuid!} {...props} /></TabPanel>)
    }

    return (
        <>
            <CustomTabs
                value={value}
                onChange={handleChange}
                rightMenu={
                    <RessourceMenu
                        isAllowedToDelete={(data) => ability.can(PermissionEnum.ORGANISATION_WRITE, data)}
                        nameOfRessource="authorisation"
                        primaryButtonText={t("Authorisation") + ": " + data.parent_user_email}
                        idToFetch={data.uuid}
                        deleteMutation={useDeleteAuthorizationMutation}
                        getByIdAction={useGetAuthorizationByIdQuery}
                        afterDeleteAction={(props as AuthorisationDetailsContentProps).afterDeleteAction} />
                }
            >
                {labels.map(label => (<Tab key={label} label={label} />))}
            </CustomTabs>
            {tabPanel.map(tabPanel => tabPanel)}
        </>
    )
}