import { AlternateEmail, PersonSearch } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { ClientBadge } from '../../../../components/Badges/ClientBadge';
import { ServiceBadge } from '../../../../components/Badges/ServiceBadge';
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import EasyFormMany from "../../../../components/Forms/EasyFormMany";
import { AutocompleteFieldRender } from "../../../../components/Forms/Renders/AutocompleteFieldRender";
import { FieldEditorRender } from "../../../../components/Forms/Renders/FieldEditorRender";
import { TextFieldRender } from "../../../../components/Forms/Renders/TextFieldRender";
import { TextRenderer } from "../../../../components/Forms/Renders/TextRenderer";
import { OpenInNewTabButton } from '../../../../components/OpenInNewTabButton';
import Loading from '../../../../components/loading';
import { PaginationQueryPackage } from "../../../../models/API/QueryParams/PaginationQueryPackage";
import { ContactResponse } from '../../../../models/Contact';
import { ContactLabel } from '../../../../models/ContactLabel';
import { useDeleteIpAddressMutation, useUpdateIpAddressMutation } from "../../../ClientApi/redux/ipAddressApiSlice";
import { contactLabelsApiSlice } from '../../../ContactsApi/redux/contactLabelApiSlice';
import { useGetContactsByClientIdQuery, useGetContactsByServiceIdQuery } from '../../../ContactsApi/redux/contactsApiSlice';
import { useGetAgreementByIdQuery } from '../../redux/agreementsApiSlice';
import { AgreementTabProps } from "../AgreementInfoButton";

class ContactResponseView extends ContactResponse {
    labels?: ContactLabel[]
    from?: ComeFromEnum
}

enum ComeFromEnum {
    SERVICE,
    CLIENT
}

export default function ContactsTab(props: AgreementTabProps) {
    const { t } = useTranslation()
    const agreement = useGetAgreementByIdQuery(props.agreementId);

    const labelsForUserLazy = contactLabelsApiSlice.useLazyGetContactLabelsForContactQuery();
    const labelsForUserLazyTrigger = labelsForUserLazy[0]

    const [agreementContacts, setAgreementContacts] = useState<ContactResponseView[]>([])
    const [loading, setLoading] = useState(false)
    const serviceContacts = useGetContactsByServiceIdQuery({
        uuid: agreement.data!.service!.uuid,
        pagination: new PaginationQueryPackage()
    })
    const clientContacts = useGetContactsByClientIdQuery({
        uuid: agreement.data!.client!.uuid,
        pagination: new PaginationQueryPackage()
    })
    useMemo(() => {
        if (clientContacts.isLoading || serviceContacts.isLoading)
            return;

        const serviceContactsView = serviceContacts.data?.map(serviceContact => {
            const contactView = serviceContact as ContactResponseView;
            contactView.from = ComeFromEnum.SERVICE
            return contactView;
        });
        const clientContactsView = clientContacts.data?.map(clientContact => {
            const contactView = clientContact as ContactResponseView;
            contactView.from = ComeFromEnum.CLIENT
            return contactView;
        });

        let allPromses: Promise<ContactResponseView>[] = []
        allPromses = (serviceContactsView ?? []).concat(clientContactsView ?? []).map(async contact => {
            const contactView = contact as ContactResponseView;

            const labels = await labelsForUserLazyTrigger({
                pagination: new PaginationQueryPackage(),
                uuid: contact.uuid
            }).unwrap();
            contactView.labels = labels ?? [];

            return contactView;
        }) ?? []
        setLoading(true)
        Promise.all(allPromses).then(contactViews => setAgreementContacts(contactViews)).finally(() => setLoading(false))

    }, [serviceContacts, clientContacts])

    const SignupSchema = Yup.object().shape({

    });

    const getRows = (contacts: ContactResponse[]) => {
        const rows: Array<FieldEditorRender[]> = []
        contacts?.forEach((contact) => {
            const newRow: FieldEditorRender[] = [
                new TextRenderer({
                    displayText: "",
                    fieldName: "",
                    nestedfieldName: "",
                    uniqueId: contact.uuid,
                    render: () =>
                        contact.user_uuid != undefined ?
                            <Box textAlign="center"><OpenInNewTabButton icon={<PersonSearch/>} tooltipText={t("Open user attached to contact")} url={`/users/${contact.user_uuid}`} /></Box> :
                            <Box textAlign="center"><OpenInNewTabButton icon={<AlternateEmail/>} tooltipText={t("Open contact without user")} url={`/contacts/${contact.external_contact_uuid}`} /></Box>
                }),
                new TextFieldRender({
                    displayText: "Email",
                    fieldName: "",
                    nestedfieldName: "email",

                    type: "text",
                    uniqueId: contact.uuid!
                }),
                new TextFieldRender({
                    displayText: "First name",
                    fieldName: "",
                    nestedfieldName: "first_name",

                    type: "text",
                    uniqueId: contact?.uuid ?? "",
                }), new TextFieldRender({
                    displayText: "Last name",
                    fieldName: "",
                    nestedfieldName: "last_name",

                    type: "text",
                    uniqueId: contact?.uuid ?? "",
                }),
                new AutocompleteFieldRender({
                    displayText: "Contact labels",
                    fieldName: "",
                    nestedfieldName: "labels",
                    multipleSelect: true,
                    getOptionLabelFunc: (option: ContactLabel) => option.label ?? "",
                    options: [],
                    uniqueId: contact.uuid!,
                }),
                new TextRenderer({
                    displayText: "",
                    fieldName: "",
                    nestedfieldName: "from",
                    uniqueId: contact.uuid,
                    render: (content) =>
                        <>
                            {(content as ComeFromEnum) == ComeFromEnum.SERVICE ? <ServiceBadge tooltip={t("Contact comes from service")} textOverride={t("Service")} /> : <></>}
                            {(content as ComeFromEnum) == ComeFromEnum.CLIENT ? <ClientBadge tooltip={t("Contact comes from service")} textOverride={t("Client")} /> : <></>}
                        </>
                }),


            ]

            rows.push(newRow)
        })
        return rows;
    }

    if (agreement.isLoading || clientContacts.isLoading || serviceContacts.isLoading || labelsForUserLazy[1].isLoading || loading)
        return (<Loading />)

    return (
        <>
            <EasyFormMany
                confirmDeletionText="Are you sure you wish to delete this ip address?"
                key={JSON.stringify(agreementContacts)}
                isAllowedToDelete={() => false}
                isAllowedToEdit={() => false}
                isAllowedToCreate={() => false}
                ressourceOwnerId={agreement.data!.service!.uuid}
                arrayPath=""
                initialValue={agreementContacts}
                getManyByIdAction={useGetContactsByServiceIdQuery}
                saveMutation={useUpdateIpAddressMutation}
                deleteMutation={useDeleteIpAddressMutation}
                SignupSchema={SignupSchema}
                idToFetch={agreement.data!.service!.uuid}
                columns={[
                    new EasyFormColumn("Contacts", "", [])
                ]}
                getFieldRows={getRows}
            />
        </>
    )
}