import { Handshake, History } from '@mui/icons-material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { CriticalityScoreBadge } from '../../components/Badges/CriticalityScoreBadge';
import DatagridWrapper, { DataGridId } from '../../components/DatagridWrapper';
import GetLastElementsInList from '../../components/Logic/ListLogic';
import { OpenInNewTabButton } from '../../components/OpenInNewTabButton';
import { PaginationQueryPackage } from '../../models/API/QueryParams/PaginationQueryPackage';
import { ServiceBase, ServiceResponse } from '../../models/Service';
import { GetManyPackage } from '../../redux/GetManyPackage';
import { PermissionEnum } from '../Permission/PermissionEnum';
import { AbilityContext, Can } from '../UserApi/logic/Can';
import { ServiceInfoButton } from './serviceInfoDialog/ServiceInfoButton';
import getEnvironment from '../../env';
import { OrganisationContext } from '../../components/Layout/OrganisationPicker';
import { NotCorrectRights } from '../Permission/NotCorrectRights';
import { GridColDef } from '@mui/x-data-grid-pro';
import { ServiceCategoryEnum } from '../../models/enums/ServiceCategoryEnum';
import { ServiceTagResponse } from '../../models/ServiceTag';
import { ServiceCategoryBadge } from '../../components/Badges/ServiceCategoryBadge';
import { CountBadge } from '../../components/CountBadge';
import { CriticalityBadge } from '../../components/Badges/CriticalityBadge';
import { PropagationBadge } from '../../components/Badges/PropagationBadge';

const getCurrentService = (services: ServiceResponse[], params: GridRenderCellParams<any, any, any>): ServiceResponse => {
    const service = services.find(x => x.uuid === params.id)!;
    return service;
}
class ServiceListProps {
    getServices?: UseQuery<QueryDefinition<GetManyPackage, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, ServiceResponse[], any>>
    services?: ServiceResponse[]
    setCheckedServices?: Dispatch<SetStateAction<ServiceResponse[]>>
    maxSelected?: number
    checkedServices?: ServiceResponse[]
    idToFetch?: string
    disableDrawer?: boolean
    extendedActions?: (service: ServiceResponse) => JSX.Element
    simpleView?: boolean = false
}
export const ServiceList = (props: ServiceListProps) => {
    const ability = useContext(AbilityContext);
    const organisationContext = useContext(OrganisationContext)?.organisation;

    const canReadList = ability.can(PermissionEnum.SERVICE_READ, organisationContext)
    if (!canReadList)
        return <NotCorrectRights />

    return (<ServiceListContent {...props} />)
}

const ServiceListContent = (props: ServiceListProps) => {
    const [clickedRow, setClickedRow] = useState<ServiceResponse | undefined>(undefined)
    const [uuid, setUuid] = useState(crypto.randomUUID()) //To rerender buttons when clicking a row

    const services = getServices(props);

    const serviceTags = [...new Set(services?.map(service => service.servicetag?.name ?? ""))]
        .filter(tag => tag !== "").sort()

    const getColumns = (services: ServiceResponse[]) => {

        const simpleView: GridColDef[] = [
            { field: "short_id", sortable: true, headerName: t('ID'), type: "number", renderCell: (params) => <div>{params.row.short_id}</div>, flex: 1 },
            { field: "name", sortable: true, minWidth: 150, headerName: t('Name'), type: "string", flex: 3 },
            { field: "criticality_score", sortable: true, minWidth: 100, headerName: t('Criticality score'), renderCell: (params) => <CriticalityScoreBadge ressource={params.row as ServiceBase} />, type: "number", flex: 1.5 },
            {
                field: "service_categories", sortable: true,
                renderCell: (params) => <>{params.row.service_categories.map((category: ServiceCategoryEnum, index: number) => <><ServiceCategoryBadge asText key={category} ressource={category} />{index + 1 != params.row.service_categories.length && <>, </>} </>)}</>,
                headerName: t('Categories'),
                type: "string",
                valueGetter: (service_categories?: ServiceCategoryEnum[]) => service_categories?.map(category => t(category)),
                flex: 2
            },
        ];

        const fullView: GridColDef[] = [
            { field: "criticality", sortable: true, headerName: t('Criticality'), renderCell: (params) => <CriticalityBadge ressource={params.row.criticality} />, type: "string", flex: 2 },
            { field: "propagation", sortable: true, headerName: t('Propagation'), renderCell: (params) => <PropagationBadge ressource={params.row.propagation} />, type: "string", flex: 2 },
            { field: "owner_organisation", sortable: true, headerName: t('Owner organisation'), type: "string", valueGetter: (_, row: ServiceResponse) => row.owner_organisation?.name, flex: 2 },
            { field: 'active_agreements', renderCell: (params) => <CountBadge prefix={t("agreements")} icon={Handshake} count={params.row.active_agreements} />, sortable: true, type: "number", headerName: t('Agreements'), flex: 1 },
            { field: "servicetag", sortable: true, headerName: t('Servicegroup'), type: "singleSelect", valueOptions: serviceTags, valueGetter: (serviceTag?: ServiceTagResponse) => serviceTag?.name, flex: 2 },
            { field: "ips", sortable: false, headerName: t('IP addresses'), type: "string", valueGetter: (_, row: ServiceResponse) => [...new Set(row.endpoints?.map(endpoint => endpoint.ip))], flex: 2 },
            { field: "dns_names", sortable: false, headerName: t('DNS names'), type: "string", valueGetter: (_, row: ServiceResponse) => [...new Set(row.endpoints?.map(endpoint => endpoint.dns_name + "." + endpoint.dns_suffix?.dns_suffix + "." + getEnvironment().REACT_APP_DSDN_DOMAIN))], flex: 2 },
        ];

        const buttonsView: GridColDef[] = [
            {
                field: 'events', 
                sortable: false, 
                hideable: false, 
                valueGetter: () => "", 
                headerName: t('Actions'), 
                type: "string", 
                minWidth: props.simpleView ? 100 : 220, 
                renderCell: (params => {
                    const service = getCurrentService(services, params)
                    return (props.simpleView ?
                        <OpenInNewTabButton url={`/services/${service.uuid}`} />
                        :
                        <>
                            {!props.disableDrawer &&
                                <ServiceInfoButton
                                    key={JSON.stringify(uuid)}
                                    openFromStart={service.uuid == clickedRow?.uuid}
                                    onClose={() => setClickedRow(undefined)}
                                    service={service!} />
                            }

                            <Can I={PermissionEnum.AUDIT_READ} this={service}>
                                <OpenInNewTabButton tooltipText='Events' icon={<History />} url={`/events?entity_uuid=${service.uuid}`} />
                            </Can>
                            <OpenInNewTabButton url={`/services/${service.uuid}`} />
                            {props.extendedActions && props.extendedActions(service)}
                        </>
                    )
                })
            }
        ]
        if (props.simpleView) {
            return simpleView.concat(buttonsView);
        } else {
            return simpleView.concat(fullView).concat(buttonsView)
        }

    }

    return (
        <DatagridWrapper
            dataGridId={props.simpleView ? DataGridId.SERVICES_SIMPLE : DataGridId.SERVICES}
            getRowId={(row) => row.uuid}
            autoHeight
            onRowClick={(row) => {
                {
                    if (!props.disableDrawer) {
                        setClickedRow(row.row);
                        setUuid(crypto.randomUUID());
                    }
                }
            }}
            loading={services == undefined}
            rows={services ?? []}
            columns={getColumns(services ?? [])}
            onRowSelectionModelChange={(selected) => {
                if (props.setCheckedServices && services) {
                    const newCheckedServices = GetLastElementsInList(selected, props.maxSelected).map(uuid => services?.find(service => service.uuid === uuid));
                    props.setCheckedServices(newCheckedServices.filter((item): item is ServiceResponse => !!item))
                }
            }}
            rowSelectionModel={props.checkedServices?.map(x => x.uuid)}
            checkboxSelection={props.checkedServices != undefined}
            initialSortModel={[{ field: 'servicetag', sort: 'asc' }]}
        />
    )

    function getServices(props: ServiceListProps) {
        if (props.services != undefined)
            return props.services


        const currentResult = props.getServices?.({
            pagination: new PaginationQueryPackage(),
            uuid: props.idToFetch
        });

        return currentResult?.data
    }
}

