import { ButtonGroup, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ConfirmationButton } from "../../../../components/ConfirmationButton";
import { DataGridId } from "../../../../components/DatagridWrapper";
import Loading from "../../../../components/loading";
import { PaginationQueryPackage } from "../../../../models/API/QueryParams/PaginationQueryPackage";
import { ApprovalStatus } from "../../../../models/enums/ApprovalStatus";
import { DetailedOrganisationResponse } from "../../../../models/Organisation";
import { AgreementList } from "../../../AgreementApi/agreementList";
import { agreementsApiSlice, useGetAgreementsByOrganisationIdQuery } from "../../../AgreementApi/redux/agreementsApiSlice";
import { AuthorisationList } from "../../../AuthorisationApi/authorisationList";
import { authorizationApiSlice, useGetAuthorizationsForOrganisationQuery } from "../../../AuthorisationApi/redux/authroisationApiSlice";
import { ClientList } from "../../../ClientApi/clientList";
import { clientsApi, useGetClientsByOrganisationIdQuery } from "../../../ClientApi/redux/clientsApiSlice";
import { ExternalContactList } from "../../../ContactsApi/externalContactList";
import { contactApiSlice, useGetExternalContactsByOrganisationIdQuery } from "../../../ContactsApi/redux/contactsApiSlice";
import { HostingSiteList } from "../../../HostingSiteApi/hostingSiteList";
import { hostingsitesApiSlice, useGetHostingsitesByOrganisationIdQuery } from "../../../HostingSiteApi/hostingsitesApiSlice";
import { serviceApiSlice, useGetServicesByOrganisationIdQuery } from "../../../ServiceApi/redux/serviceApiSlice";
import { ServiceList } from "../../../ServiceApi/serviceList";
import { useGetAllUsersByOrganisationQuery, usersApiSlice } from "../../../UserApi/redux/userApiSlice";
import { UserList } from "../../../UserApi/usersList";
import HostingsiteBase from "../../../../models/Hostingsite";

export interface OrganisationDeleteContentProps {
    organisation: DetailedOrganisationResponse
    setReadyForDelete: (ready: boolean) => void
}

export const OrganisationDeleteContent = (props: OrganisationDeleteContentProps) => {
    const { t } = useTranslation()

    let services = useGetServicesByOrganisationIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: props.organisation.uuid
    }).data;

    let activeAgreements = useGetAgreementsByOrganisationIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: props.organisation.uuid,
        requestData: [ApprovalStatus.APPROVED]
    }).data;

    let clients = useGetClientsByOrganisationIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: props.organisation.uuid,
    }).data;

    let hostingSites = useGetHostingsitesByOrganisationIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: props.organisation.uuid,
    }).data;

    const users = useGetAllUsersByOrganisationQuery({
        pagination: new PaginationQueryPackage(),
        uuid: props.organisation.uuid,
    }).data;

    const authorisations = useGetAuthorizationsForOrganisationQuery({
        pagination: new PaginationQueryPackage(),
        uuid: props.organisation.uuid,
    }).data;

    const externalContacts = useGetExternalContactsByOrganisationIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: props.organisation.uuid,
    }).data;

    const deleteAgreementTrigger = agreementsApiSlice.useDeleteAgreementMutation()[0];
    const deleteServiceTrigger = serviceApiSlice.useDeleteServiceByUuidMutation()[0];
    const deleteClientTrigger = clientsApi.useDeleteClientMutation()[0];
    const deleteHostingSiteTrigger = hostingsitesApiSlice.useDeleteHostingsiteMutation()[0];
    const deleteUserTrigger = usersApiSlice.useDeleteUserMutation()[0];
    const deleteAuthorisationTrigger = authorizationApiSlice.useDeleteAuthorizationMutation()[0];
    const deleteExternalContactTrigger = contactApiSlice.useDeleteExternalContactMutation()[0];
    const moveHostingSiteTrigger = hostingsitesApiSlice.useMoveOrganisationsHostingsiteMutation()[0];

    if (activeAgreements == undefined || services == undefined || clients == undefined || hostingSites == undefined || users == undefined || authorisations == undefined || externalContacts == undefined)
        return <Loading />

    activeAgreements = activeAgreements.filter(agreement =>
        agreement.service?.owner_organisation?.uuid === props.organisation.uuid
        || agreement.client?.hosting_site?.owner_organisation?.uuid === props.organisation.uuid)
    services = services.filter(service => service.owner_organisation?.uuid === props.organisation.uuid)
    clients = clients.filter(client => client.hosting_site?.owner_organisation?.uuid === props.organisation.uuid)
    const administratedHostingSites = hostingSites.filter(hostingSite => hostingSite.owner_organisation?.uuid !== props.organisation.uuid)
    hostingSites = hostingSites.filter(hostingSite => hostingSite.owner_organisation?.uuid === props.organisation.uuid)

    const organisationHasAdministratedHostingSites: boolean = administratedHostingSites.length > 0
    const organisationHasActiveAgreements: boolean = activeAgreements.length > 0
    const organisationHasServices: boolean = services.length > 0
    const organisationHasClients: boolean = clients.length > 0
    const organisationHasHostingSites: boolean = hostingSites.length > 0
    const organisationHasUsers: boolean = users.length > 0
    const organisationHasAuthorisations: boolean = authorisations.length > 0
    const organisationHasExternalContacts: boolean = externalContacts.length > 0

    if (!(organisationHasAdministratedHostingSites || organisationHasActiveAgreements || organisationHasServices || organisationHasClients || organisationHasHostingSites || organisationHasUsers || organisationHasAuthorisations || organisationHasExternalContacts)) {
        props.setReadyForDelete(true)
        return null
    }

    return (
        <Stack spacing={5}>
            {organisationHasAdministratedHostingSites &&
                showListWithDeleteAllButton(
                    t("The organisation is administrating hosting sites, that needs to be surrendered to the owner organisation in order to delete organisation"),
                    <HostingSiteList dataGridId={DataGridId.HOSTING_SITES_SIMPLE} simpleView={true} hostingSites={administratedHostingSites}></HostingSiteList>,
                    async () => {
                        const promises = administratedHostingSites!.map(async (hostingSite) => {
                            const movedHostingSite = new HostingsiteBase()
                            movedHostingSite.uuid = hostingSite.uuid
                            movedHostingSite.owner_organisation = hostingSite.owner_organisation
                            movedHostingSite.administrating_organisation = hostingSite.owner_organisation
                            return moveHostingSiteTrigger({ data: movedHostingSite })
                        })
                        await Promise.all(promises)
                    },
                    t("Surrender all hosting sites"),
                    t("Are you sure you wish to surrender all hosting sites administrated by this organisation?", { "numberOfHostingSites": administratedHostingSites?.length })
                )}
            {organisationHasActiveAgreements &&
                showListWithDeleteAllButton(
                    t("The organisation has active agreements, that needs to be terminated in order to delete organisation"),
                    <AgreementList dataGridId={DataGridId.AGREEMENTS_SIMPLE} statusList={[ApprovalStatus.APPROVED]} simpleView={true} agreements={activeAgreements}></AgreementList>,
                    async () => {
                        const promises = activeAgreements!.map(async (agreement) => deleteAgreementTrigger({ data: agreement }));
                        await Promise.all(promises);
                    },
                    t("Delete all agreements"),
                    t("Are you sure you wish to delete all agreements in this organisation?", { "numberOfAgreements": activeAgreements?.length })
                )}
            {organisationHasServices &&
                showListWithDeleteAllButton(
                    t("The organisation has services, that needs to be terminated in order to delete organisation"),
                    <ServiceList simpleView={true} services={services}></ServiceList>,
                    async () => {
                        const promises = services!.map(async (service) => deleteServiceTrigger({ data: service.uuid }))
                        await Promise.all(promises)
                    },
                    t("Delete all services"),
                    t("Are you sure you wish to delete all services in this organisation?", { "numberOfServices": services?.length })
                )}
            {organisationHasClients &&
                showListWithDeleteAllButton(
                    t("The organisation has clients, that needs to be terminated in order to delete organisation"),
                    <ClientList dataGridId={DataGridId.CLIENTS_SIMPLE} simpleView={true} clients={clients}></ClientList>,
                    async () => {
                        const promises = clients!.map(async (client) => deleteClientTrigger({ data: client }))
                        await Promise.all(promises)
                    },
                    t("Delete all clients"),
                    t("Are you sure you wish to delete all clients in this organisation?", { "numberOfClients": clients?.length })
                )}
            {organisationHasHostingSites &&
                showListWithDeleteAllButton(
                    t("The organisation has hosting sites, that needs to be terminated in order to delete organisation"),
                    <HostingSiteList dataGridId={DataGridId.HOSTING_SITES_SIMPLE} simpleView={true} hostingSites={hostingSites}></HostingSiteList>,
                    async () => {
                        const promises = hostingSites!.map(async (hostingSite) => deleteHostingSiteTrigger({ data: hostingSite }))
                        await Promise.all(promises)
                    },
                    t("Delete all hosting sites"),
                    t("Are you sure you wish to delete all hosting sites in this organisation?", { "numberOfHostingSites": hostingSites?.length })
                )}
            {organisationHasAuthorisations &&
                showListWithDeleteAllButton(
                    t("The organisation has authorisations, that needs to be terminated in order to delete organisation"),
                    <AuthorisationList dataGridId={DataGridId.AUTHORISATIONS_SIMPLE} simpleView={true} idToFetch={props.organisation.uuid} getAuthorisations={authorizationApiSlice.useGetAuthorizationsForOrganisationQuery}></AuthorisationList>,
                    async () => {
                        const promises = authorisations!.map(async (authorisation) => deleteAuthorisationTrigger({ data: authorisation }))
                        await Promise.all(promises)
                    },
                    t("Delete all authorisations"),
                    t("Are you sure you wish to delete all authorisations in this organisation?", { "numberOfAuthorisations": authorisations?.length })
                )}
            {organisationHasUsers &&
                showListWithDeleteAllButton(
                    t("The organisation has users, that needs to be terminated in order to delete organisation"),
                    <UserList dataGridId={DataGridId.USERS_SIMPLE} simpleView={true} idToFetch={props.organisation.uuid} getUsers={usersApiSlice.useGetAllUsersByOrganisationQuery}></UserList>,
                    async () => {
                        const promises = users!.map(async (user) => deleteUserTrigger({ data: user }))
                        await Promise.all(promises)
                    },
                    t("Delete all users"),
                    t("Are you sure you wish to delete all users in this organisation?", { "numberOfUsers": users?.length })
                )}
            {organisationHasExternalContacts &&
                showListWithDeleteAllButton(
                    t("The organisation has external contacts, that needs to be terminated in order to delete organisation"),
                    <ExternalContactList simpleView={true} idToFetch={props.organisation.uuid} getExternalContacts={contactApiSlice.useGetExternalContactsByOrganisationIdQuery}></ExternalContactList>,
                    async () => {
                        const promises = externalContacts!.map(async (externalContact) => deleteExternalContactTrigger({ data: externalContact }))
                        await Promise.all(promises)
                    },
                    t("Delete all external contacts"),
                    t("Are you sure you wish to delete all external contacts in this organisation?", { "numberOfExternalContacts": externalContacts?.length })
                )}
        </Stack>
    )

    function showListWithDeleteAllButton(description: string, listOfElements: JSX.Element, deleteAllAction: () => Promise<void>, deleteAll: string, confirmationText: string) {
        return <Stack spacing={2}>
            <Typography>{description}</Typography>
            {listOfElements}
            <ButtonGroup>
                <ConfirmationButton
                    maxWidth="lg"
                    action={deleteAllAction}
                    title={deleteAll}
                    buttonText={deleteAll}>
                    <>
                        <Typography>{confirmationText}</Typography>
                        <Typography fontWeight="bold">{t("You will not be able to undo this action")}</Typography>
                    </>
                </ConfirmationButton>
            </ButtonGroup>
            <Divider></Divider>
        </Stack>
    }
}