import BaseModel from "./BaseModels/BaseModel"

export class CPE extends BaseModel {
  sdn_v4_id?: string
  theft_mark?: string
  vlan_name?: string
  setup_type?: SetupEnum
  failover?: FailoverEnum
}


// High availability setup
export enum SetupEnum {
  SOFTWARE_APPLIANCE = "SOFTWARE_APPLIANCE",
  _40_F = "40F",
  _100_F = "100F"
}

export enum FailoverEnum {
  NO_REDUNDANCY = "NO_REDUNDANCY",
  // Border Gateway Protocol
  BGP = "BGP",
  // high availability
  HA = "HA"
}


 // Convert enum value to a human-readable string
 export function failoverToString(failover: FailoverEnum): string {
  switch (failover) {
      case FailoverEnum.NO_REDUNDANCY: return "No Redundancy"
      case FailoverEnum.BGP: return "BGP"
      case FailoverEnum.HA: return "HA"
  }
}

// Convert enum value to a human-readable string
export function setupTypeToString(setup: SetupEnum): string {
  switch (setup) {
      case SetupEnum.SOFTWARE_APPLIANCE: return "Software Appliance"
      case SetupEnum._40_F: return "40F"
      case SetupEnum._100_F: return "100F"
  }
}






