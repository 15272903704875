import { FieldArray, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import EasyFormColumn from '../../../components/Forms/EasyFormColumn';
import { FormType } from '../../../components/Forms/EasyFormMany';
import { EasyFormRow } from '../../../components/Forms/FormLayout';
import { AutocompleteFieldRender } from '../../../components/Forms/Renders/AutocompleteFieldRender';
import { FieldEditorRender } from '../../../components/Forms/Renders/FieldEditorRender';
import { TextFieldRender } from '../../../components/Forms/Renders/TextFieldRender';
import HostingsiteBase from '../../../models/Hostingsite';
import { CreateOrganisationRequest, OrganisationCPE } from '../../../models/Organisation';
import { t } from "i18next";
import * as Yup from 'yup';
import { FailoverEnum, failoverToString, SetupEnum, setupTypeToString } from '../../../models/CPE';


export function getCPEValidationScheme() {
  return Yup.object().shape({
    cpes: Yup.array().of(
      Yup.object().shape({
        hosting_site: Yup.object().required(t('Required')),
        sdn_v4_id: Yup.string().nullable().matches(/^[a-z0-9]*$/, t("Lowercase and numbers"))
          .test("sdn_v4_id_length", t("must be exactly 0 or 8 characters"), value => value == undefined || value.length === 0 || value.length === 8),
        theft_mark: Yup.string().max(50).nullable(),
        vlan_name: Yup.string().max(15).nullable().matches(/^[a-zA-Z0-9_-]*$/, t("Vlan format"))
      })
    ).test("unique_sdn_v4_ids", t("No SDN V4 ID duplicates allowed in same hosting site"),
      list => {
        const hsSdnV4Ids = list?.filter(cpe => cpe.sdn_v4_id != undefined && cpe.sdn_v4_id.length > 0).map(cpe => cpe.hosting_site?.uuid + cpe.sdn_v4_id);
        return hsSdnV4Ids == undefined || hsSdnV4Ids.length == new Set(hsSdnV4Ids).size;
      })
  });
}

function getRows(
  organisationRequest: CreateOrganisationRequest
): Array<FieldEditorRender[]> {

  const rows: Array<FieldEditorRender[]> = []

  organisationRequest.cpes?.forEach((cpe) => {
    const newRow: FieldEditorRender[] = [
      new AutocompleteFieldRender({
        displayText: "Hosting site",
        fieldName: "cpes",
        nestedfieldName: "hosting_site",
        getOptionLabelFunc: (option: HostingsiteBase) => option.name ?? "",
        options: organisationRequest.hosting_sites,
        renderNonChangeable: (content) => <>{content?.toString()}</>,
        uniqueId: cpe.uuid ?? "no_id",
        showLabel: true,
        columns: true
      }),
      new TextFieldRender({
        displayText: "SDN V4 ID",
        showLabel: true,
        fieldName: "cpes",
        nestedfieldName: "sdn_v4_id",
        type: "text",
        columns: true,
        uniqueId: cpe.uuid ?? "no_id"
      }),
      new TextFieldRender({
        displayText: "Theft mark",
        showLabel: true,
        fieldName: "cpes",
        nestedfieldName: "theft_mark",
        type: "text",
        columns: true,
        uniqueId: cpe.uuid ?? "no_id"
      }),
      new TextFieldRender({
        displayText: "Vlan name",
        showLabel: true,
        fieldName: "cpes",
        nestedfieldName: "vlan_name",
        type: "text",
        columns: true,
        uniqueId: cpe.uuid ?? "no_id"
      }),
      new AutocompleteFieldRender({
        displayText: "Setup",
        showLabel: true,
        fieldName: "setup_type",
        nestedfieldName: "setup_type",
        minWidth: 300,
        uniqueId: cpe.uuid ?? "no_id",
        onChange: (setup: SetupEnum) => { cpe.setup_type = setup },
        options: Object.values(SetupEnum) ?? [],
        renderNonChangeable: (content) => <>{content}</>,
        getOptionLabelFunc: (option: SetupEnum) => setupTypeToString(option) ?? "",
      }),
      
      new AutocompleteFieldRender({
        displayText: "Failover",
        showLabel: true,
        fieldName: "failover",
        nestedfieldName: "failover",
        minWidth: 300,
        options: Object.values(FailoverEnum) ?? [],
        uniqueId: cpe.uuid ?? "no_id",
        onChange: (failover: FailoverEnum) => { cpe.failover = failover },
        renderNonChangeable: (content) => <>{content}</>,
        getOptionLabelFunc: (option: FailoverEnum) => failoverToString(option) ?? "",

      }),
    ]
    rows.push(newRow)
  })
  return rows;
}

export default function CPEStep(props: { formikProps: FormikProps<CreateOrganisationRequest>, formPropsStuf: any }) {
  const { t } = useTranslation();
  return (
    <FieldArray
      name="cpes"
      render={arrayHelpers => (
        <EasyFormRow
          confirmDeletionText={t("Are you sure you wish to remove this CPE?")}
          {...props.formikProps}
          {...props.formPropsStuf}
          editModeAlways={true}
          editable={true}
          isDeleteable={true}
          saveMutation={() => { throw "Dont save plz" }}
          formType={FormType.LIST}
          createNew={() => new OrganisationCPE()}
          editMode={true}
          getFieldRows={(organisationRequest) => getRows(organisationRequest as CreateOrganisationRequest)}
          columns={[
            new EasyFormColumn("CPE", "", [])
          ]}
          arrayHelpers={arrayHelpers} />
      )
      }
    />


  )
}
