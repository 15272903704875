import { History } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import DatagridWrapper, { DataGridId } from '../../components/DatagridWrapper';
import { OrganisationContext } from '../../components/Layout/OrganisationPicker';
import { OpenInNewTabButton } from '../../components/OpenInNewTabButton';
import { PaginationQueryPackage } from '../../models/API/QueryParams/PaginationQueryPackage';
import { Role, UserAuthorisationResponse, roleToString } from '../../models/User';
import { GetManyPackage } from '../../redux/GetManyPackage';
import { NotCorrectRights } from '../Permission/NotCorrectRights';
import { PermissionEnum } from '../Permission/PermissionEnum';
import { AbilityContext, Can } from '../UserApi/logic/Can';
import { AuthorisationDetailsButton } from './authorisationInfoDialog/AuthorisationDetailsButton';
import { DateToFormattedString } from '../../components/Logic/DateLogic';


class AuthorisationListProps {
    getAuthorisations!: UseQuery<QueryDefinition<GetManyPackage, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, UserAuthorisationResponse[], any>>
    maxSelected?: number
    checkedAuthorisations?: UserAuthorisationResponse[]
    idToFetch?: string
    simpleView?: boolean = false;
    dataGridId?: DataGridId
}

export const AuthorisationList = (props: AuthorisationListProps) => {
    const ability = useContext(AbilityContext);
    const organisationContext = useContext(OrganisationContext)?.organisation;

    const canReadList = ability.can(PermissionEnum.ORGANISATION_READ, organisationContext)
    if (!canReadList)
        return <NotCorrectRights />

    return (<AuthorisationListContent {...props} />)
}

export function AuthorisationListContent(props: AuthorisationListProps) {
    const currentResult = props.getAuthorisations({
        pagination: new PaginationQueryPackage(),
        uuid: props.idToFetch
    })

    const [clickedRow, setClickedRow] = useState<UserAuthorisationResponse | undefined>(undefined)
    const [uuid, setUuid] = useState(crypto.randomUUID()) //To rerender buttons when clicking a row

    if (currentResult.data === undefined)
        currentResult.data = []

    const getColumns = (authorisations: UserAuthorisationResponse[]) => {
        const simpleView: GridColDef[] = [
            //Data
            { field: "parent_user_organisation_name", sortable: true, type: "string", headerName: t('User organisation'), flex: 1 },
            { field: "organisation_name", sortable: true, type: "string", headerName: t('Authorisation to'), flex: 1 },
            { field: "parent_user_name", sortable: true, type: "string", headerName: t('Name'), valueGetter: (_, row: UserAuthorisationResponse) => row.parent_user_first_name + " " + row.parent_user_last_name, flex: 1 },
            { field: "parent_user_email", sortable: true, type: "string", headerName: t('Email'), flex: 1 },
        ];

        const advancedView: GridColDef[] = [
            { field: 'role', sortable: true, type: "singleSelect", valueOptions: Object.values(Role).map(e => t(e) + ""), headerName: t('Role'), valueGetter: (role: Role) => t(roleToString(role)), flex: 1 },
            { field: "latest_activity", sortable: true, type: "dateTime", headerName: t('Latest activity'), valueGetter: (latestActivity) => latestActivity && new Date(latestActivity), renderCell: (params: { row: UserAuthorisationResponse }) => DateToFormattedString(params.row.latest_activity), flex: 1 },
        ];

        const buttonsView: GridColDef[] = [
            {
                field: 'events',
                sortable: false,
                hideable: false,
                type: "string",
                valueGetter: () => "",
                headerName: t('Actions'),
                minWidth: props.simpleView ? 100 : 220,
                renderCell: (params: any) => {
                    const authorisation = authorisations.find(x => x.uuid === params.id);
                    return (props.simpleView ?
                        <OpenInNewTabButton url={`/authorisations/${authorisation?.uuid}`} />
                        :
                        <>
                            <Can I={PermissionEnum.ORGANISATION_READ} this={authorisation}>
                                <AuthorisationDetailsButton
                                    key={JSON.stringify(uuid)}
                                    openFromStart={authorisation?.uuid === clickedRow?.uuid}
                                    onClose={() => setClickedRow(undefined)}
                                    authorisation_uuid={authorisation?.uuid ?? "not used"} />
                            </Can>
                            <Can I={PermissionEnum.AUDIT_READ} this={authorisation}>
                                <OpenInNewTabButton tooltipText='Events' icon={<History />} url={`/events?entity_uuid=${authorisation?.uuid}`} />
                            </Can>
                            <Can I={PermissionEnum.ORGANISATION_READ} this={authorisation}>
                                <OpenInNewTabButton url={`/authorisations/${authorisation?.uuid}`} />
                            </Can>
                        </>
                    )
                }
            }
        ];

        const simpleList = simpleView;
        const advancedList = advancedView;
        const listToShow = props.simpleView ? simpleList : simpleList.concat(advancedList);
        return listToShow.concat(buttonsView);
    }

    return (
        <DatagridWrapper
            dataGridId={props.dataGridId ?? DataGridId.AUTHORISATIONS}
            onRowClick={(row) => { setClickedRow(row.row); setUuid(crypto.randomUUID()) }}
            loading={currentResult.isLoading}
            rows={currentResult.data}
            columns={getColumns(currentResult.data)}
        />
    )
}

