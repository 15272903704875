import { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { CreateInCollectionButton } from "../../../../components/CreateInCollectionButton";
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import EasyFormMany from "../../../../components/Forms/EasyFormMany";
import { FieldEditorRender } from "../../../../components/Forms/Renders/FieldEditorRender";
import { TextFieldRender, TextFieldRenderPackage } from "../../../../components/Forms/Renders/TextFieldRender";
import { CPE, FailoverEnum, failoverToString, SetupEnum, setupTypeToString } from "../../../../models/CPE";
import { HostingsiteResponse } from "../../../../models/Hostingsite";
import { PermissionEnum } from "../../../Permission/PermissionEnum";
import { AbilityContext } from "../../../UserApi/logic/Can";
import { useAddCPEMutation, useDeleteCPEMutation, useGetCPEsByHostingSiteIdQuery, useUpdateCPEMutation } from "../../cpeApiSlice";
import { AutocompleteFieldRender } from "../../../../components/Forms/Renders/AutocompleteFieldRender";


interface CPETabProps {
  hostingSite: HostingsiteResponse
}

function textRenderPackageTemplate(cpe: CPE): TextFieldRenderPackage {
  return {
    displayText: "",
    fieldName: "",
    nestedfieldName: "",
    type: "text",
    uniqueId: cpe?.uuid ?? "no_id_yet"
  }
}

export default function CPETab(props: CPETabProps) {
  const ability = useContext(AbilityContext)

  const getCPERows = (resultData: CPE[] | undefined) => {
    const rows: Array<FieldEditorRender[]> = []
    resultData?.forEach((cpe) => {
      const newRow: FieldEditorRender[] = [
        new TextFieldRender({
          ...textRenderPackageTemplate(cpe),
          displayText: "SDN V4 ID",
          nestedfieldName: "sdn_v4_id",
        }),
        new TextFieldRender({
          ...textRenderPackageTemplate(cpe),
          displayText: "Theft mark",
          nestedfieldName: "theft_mark",
        }),
        new TextFieldRender({
          ...textRenderPackageTemplate(cpe),
          displayText: "Vlan name",
          nestedfieldName: "vlan_name",
        }),

        new AutocompleteFieldRender({
          displayText: "Setup",
          showLabel: true,
          fieldName: "",
          nestedfieldName: "setup_type",
          minWidth: 300,
          uniqueId: cpe.uuid ?? "no_id",
          onChange: (setup: SetupEnum) => { cpe.setup_type = setup },
          options: Object.values(SetupEnum) ?? [],
          renderNonChangeable: (content) => <>{setupTypeToString(content)}</>,
          getOptionLabelFunc: (option: SetupEnum) => setupTypeToString(option) ?? "",
        }),
        
        new AutocompleteFieldRender({
          displayText: "Failover",
          showLabel: true,
          fieldName: "",
          nestedfieldName: "failover",
          minWidth: 300,
          options: Object.values(FailoverEnum) ?? [],
          uniqueId: cpe.uuid ?? "no_id",
          onChange: (failover: FailoverEnum) => { cpe.failover = failover },
          renderNonChangeable: (content) => <>{failoverToString(content)}</>,
          getOptionLabelFunc: (option: FailoverEnum) => failoverToString(option) ?? "",
  
        }),

      ]

      rows.push(newRow)
    })
    return rows;
  }

  return (
    <EasyFormMany
      confirmDeletionText="Are you sure you wish to delete this CPE?"
      isAllowedToDelete={() => ability.can(PermissionEnum.HOSTING_SITE_WRITE, props.hostingSite)}
      isAllowedToEdit={() => ability.can(PermissionEnum.HOSTING_SITE_WRITE, props.hostingSite)}
      isAllowedToCreate={() => ability.can(PermissionEnum.HOSTING_SITE_WRITE, props.hostingSite)}
      ressourceOwnerId={props.hostingSite.uuid}
      arrayPath=""
      getManyByIdAction={useGetCPEsByHostingSiteIdQuery}
      saveMutation={useUpdateCPEMutation}
      deleteMutation={useDeleteCPEMutation}
      createButtonJsx={
        <CreateInCollectionButton
          validationScheme={signupSchema(props.hostingSite.cpes)}
          ressourceOwnerId={props.hostingSite.uuid}
          fieldEditorRenders={getCPERows([new CPE()]).find(() => true)!}
          tooltip="Create cpe"
          createNew={() => new CPE()}
          saveMutation={useAddCPEMutation} />
      }
      editButtonJsx={(cpe: CPE) =>
        <CreateInCollectionButton
          validationScheme={signupSchema(props.hostingSite.cpes, cpe.uuid)}
          toEdit={cpe}
          createNew={() => { throw new Error("Dont create new here") }}
          ressourceOwnerId={props.hostingSite.uuid}
          fieldEditorRenders={getCPERows([new CPE()]).find(() => true)!}
          tooltip="Edit cpe"
          saveMutation={useUpdateCPEMutation}
        />

      }

      SignupSchema={signupSchema(props.hostingSite.cpes)}
      idToFetch={props.hostingSite.uuid}
      columns={[
        new EasyFormColumn("CPE", "", [])
      ]}
      getFieldRows={getCPERows}
      newButtonTooltip="Add new CPE entry"
    />
  )
}

function signupSchema(existingCpes?: CPE[], cpeUuid?: string) {
  const { t } = useTranslation()
  const invalidSdnV4Ids = (existingCpes ?? [])
    .filter((cpe) => cpeUuid == undefined || cpeUuid != cpe.uuid)
    .map((cpe) => cpe.sdn_v4_id);

  return Yup.object().shape({
    sdn_v4_id: Yup.string().nullable().matches(/^[a-z0-9]*$/, t("Lowercase and numbers"))
      .test("sdn_v4_id_length", t("must be exactly 0 or 8 characters"), value => value == undefined || value.length === 0 || value.length === 8)
      .test("sdn uniqueness", t("Already in hosting site"), (id) => id == null || id == undefined || !invalidSdnV4Ids.includes(id)),
    theft_mark: Yup.string().max(50).nullable(),
    vlan_name: Yup.string().max(15).nullable().matches(/^[a-zA-Z0-9_-]*$/, t("Vlan format"))
  });
}



