import { History } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatagridWrapper, { DataGridId } from '../../components/DatagridWrapper';
import { OpenInNewTabButton } from '../../components/OpenInNewTabButton';
import { PaginationQueryPackage } from '../../models/API/QueryParams/PaginationQueryPackage';
import { HostingsiteResponse } from '../../models/Hostingsite';
import { OrganisationResponse } from '../../models/Organisation';
import { GetManyPackage } from '../../redux/GetManyPackage';
import { NotCorrectRights } from '../Permission/NotCorrectRights';
import { PermissionEnum } from '../Permission/PermissionEnum';
import { AbilityContext, Can } from '../UserApi/logic/Can';
import { HostingSiteInfoButton } from './hostingSiteInfoDialog/HostingSiteInfoButton';

class HostingSiteListProps {
    getHostingSites?: UseQuery<QueryDefinition<GetManyPackage, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, HostingsiteResponse[], any>>
    idToFetch?: string
    hostingSites?: HostingsiteResponse[]
    simpleView?: boolean = false;
    dataGridId?: DataGridId
}

export const HostingSiteList = (props: HostingSiteListProps) => {
    const ability = useContext(AbilityContext);

    const canReadList = ability.can(PermissionEnum.HOSTING_SITE_READ)
    if (!canReadList)
        return <NotCorrectRights />

    return (<HostingSiteListContent {...props} />)
}

const HostingSiteListContent = (props: HostingSiteListProps) => {
    const [clickedRow, setClickedRow] = useState<HostingsiteResponse | undefined>(undefined)
    const [uuid, setUuid] = useState(crypto.randomUUID()) //To rerender buttons when clicking a row
    const { t } = useTranslation()

    const getColumns = (hostingSites: HostingsiteResponse[]) => {

        const simpleView: GridColDef[] = [
            {
                headerName: t('Name'),
                field: "name",
                sortable: true,
                type: "string",
                flex: 1
            },
            {
                headerName: t('Address'),
                field: "address",
                sortable: true,
                type: "string",
                flex: 1
            },
            {
                headerName: t('Owner organisation'),
                field: 'owner_organisation',
                sortable: true,
                type: "string",
                valueGetter: (org: OrganisationResponse) => org?.name,
                flex: 2
            },
            {
                headerName: t('Administration organisation'),
                field: 'administrating_organisation',
                sortable: true,
                type: "string",
                valueGetter: (org: OrganisationResponse) => org?.name,
                flex: 2
            }
        ];
        const advancedView: GridColDef[] = [
            {
                headerName: t('SDN V4 IDs'),
                field: "sdn_v4_ids",
                sortable: true,
                type: "string",
                valueGetter: (_, row: HostingsiteResponse) => row.cpes?.map(cpe => cpe.sdn_v4_id).filter(sdn_v4_id => sdn_v4_id != undefined && sdn_v4_id != ""),
                flex: 1
            },
            {
                headerName: t('Theft marks'),
                field: "theft_marks",
                sortable: true,
                type: "string",
                valueGetter: (_, row: HostingsiteResponse) => row.cpes?.map(cpe => cpe.theft_mark).filter(theft_mark => theft_mark != undefined && theft_mark != ""),
                flex: 1
            },
            {
                headerName: t('Vlan names'),
                field: "vlan_names",
                sortable: true,
                type: "string",
                valueGetter: (_, row: HostingsiteResponse) => row.cpes?.map(cpe => cpe.vlan_name).filter(vlan_name => vlan_name != undefined && vlan_name != ""),
                flex: 1
            }
        ];
        const buttonsView: GridColDef[] = [
            {
                headerName: t('Actions'),
                field: 'events',
                sortable: false,
                hideable: false,
                type: "string",
                valueGetter: () => "",
                minWidth: props.simpleView ? 100: 220,
                renderCell: (params => {
                    const hostingSite = hostingSites.find(x => x.uuid === params.id)!;
                    return (props.simpleView ?
                        <OpenInNewTabButton url={`/hosting-sites/${hostingSite.uuid}`} />
                        :
                        <>
                            <HostingSiteInfoButton
                                key={JSON.stringify(uuid)}
                                openFromStart={hostingSite.uuid == clickedRow?.uuid}
                                onClose={() => setClickedRow(undefined)}
                                hostingSite={hostingSite} />
                            <Can I={PermissionEnum.AUDIT_READ} this={hostingSite}>
                                <OpenInNewTabButton tooltipText='Events' icon={<History />} url={`/events?entity_uuid=${hostingSite.uuid}`} />
                            </Can>
                            <OpenInNewTabButton url={`/hosting-sites/${hostingSite.uuid}`} />
                        </>
                    )
                })
            }
        ];

        const simpleList = simpleView;
        const advancedList = advancedView;
        const listToShow = props.simpleView ? simpleList : simpleList.concat(advancedList);
        return listToShow.concat(buttonsView);
    }

    const hostingSites = getHostingSites(props)

    return (
        <DatagridWrapper
            dataGridId={props.dataGridId ?? DataGridId.HOSTING_SITES}
            onRowClick={(row) => { setClickedRow(row.row); setUuid(crypto.randomUUID()) }}
            loading={hostingSites == undefined}
            rows={hostingSites ?? []}
            columns={getColumns(hostingSites ?? [])}
        />
    )

    function getHostingSites(props: HostingSiteListProps) {
        if (props.hostingSites != undefined)
            return props.hostingSites


        const currentResult = props.getHostingSites?.({
            pagination: new PaginationQueryPackage(),
            uuid: props.idToFetch
        });

        return currentResult?.data
    }
}

